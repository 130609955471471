<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-ShippingLineCode-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{$t('label.listOfMovementsRestow')}}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>

        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')"
                    v-model="textSearch"
                    required
                >
                </CInput>
            </CCol>

            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="filterUnitMeasureWeight"
                    required 
                    add-label-classes="text-right"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    v-model="SizeChanged"
                    :value.sync="SizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    rowSelection="single"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
                
            </CCol>
        </CRow>
    </div>
</template>


<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    //Data
    function data() {
        return {
            loadingOverlay: false,
            dataBuque:{
                CountryName:'',
                DatePlanning:'',
                VesselFlagRoute:'',
            },
            textSearch:'',
            gridOptions: null,
            rows: [],
            SizeChanged:10,
            defaultColDef: null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            paginationPageSize:null,
            paginationNumberFormatter: null,
            dataWeight:[],
            unitMeasureId:null,
            nulo:'', 
        }
    }

    //mounted
    function beforeMount() {
        this.gridOptions = {};

        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        //console.log(this.gridApi);
    }
    function filterUnitMeasureWeight(event){
        if(event.target.value !== '' && event.target.value !== null){
            this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;

            let CargoSumaryJson = {
                UnitMeasureId: event.target.value,
                EdiFileId: this.EdiFileId,
                StowagePlanningId: (["", null, undefined].includes(this.StowagePlanning)) ? "": this.StowagePlanning 
            }

            this.$http.ejecutar('POST', 'StowagePlanningCargoRestow-list', CargoSumaryJson, { root: 'CargoSumaryJson' })
            .then(response => {
                this.rows = response.data.data;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$store.state.planificacionestiba.messageError = err;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
        }
    }
    async function onBtnExport(valor){
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(rowData.length !== 0){
            await this.getExcel(rowData,this.$t('label.listOfMovementsRestow'),valor);
        }
        
        this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
    }
    async function onBtnPdf() {
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        try{
            this.loadingOverlay = true;
            if(rowData.length !== 0){
                this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
                let computedItems = [];
                rowData.map(function(item){
                    computedItems.push({
                        IdX: item.IdX,
                        ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                        size: item.Size ? item.Size : '',
                        Status: item.Status ? item.Status : '',
                        DischargePort: item.DischargePort ? item.DischargePort : '',
                        weigth: formatMilDecimal(Number.parseFloat(item.Weigth).toFixed(2)),
                        ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                        ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                        ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                        UnNumber: item.UnNumber ? item.UnNumber : '',
                        ArrivalPosition: item.ArrivalPosition ? item.ArrivalPosition : '',
                        DepartPosition: item.DepartPosition ? item.DepartPosition : '',
                        restowType: item.TransacStowageName ? item.TransacStowageName : '',
                        reason: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                        observation_reason: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                        LoadPort: item.LoadPort ? item.LoadPort : '',
                        TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                        Seals: item.Seals ? item.Seals : '', 
                        IsoCode: item.IsoCode ? item.IsoCode : '', 
                        TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                        DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '',
                        Procedence: item.Procedence,
                        YardNamePlanning: item.YardNamePlanning, 
                        FgDirect: item.FgDirect 
                    })
                })
                await this.getPdf(computedItems,'RESTOW LIST');
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }else{
                this.$notify({  
                    group: 'container',
                    title: '¡Error!',
                    text: this.$t('label.noRecordsAvailbleReport'),
                    type: "error" 
                });
            }    
            this.loadingOverlay = false;
        } catch (error) {
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            this.$store.commit('planificacionestiba/messageMutation', error);
        }
    }
    function getRestowList() {
        this.$store.state.planificacionestiba.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'UnitMeasureWeigth-list', {
            Filter: 'ACTIVO', 
        }).then(responseWeigth => {
            this.dataWeight = responseWeigth.data.data;
            this.unitMeasureId = this.dataWeight[this.dataWeight.length-1].UnitMeasureId;

            let CargoSumaryJson = {
                UnitMeasureId: this.unitMeasureId,
                EdiFileId: this.EdiFileId,
                StowagePlanningId: (["", null, undefined].includes(this.StowagePlanning)) ? "": this.StowagePlanning 
            }

            this.$http.ejecutar('POST', 'StowagePlanningCargoRestow-list', CargoSumaryJson, { root: 'CargoSumaryJson' })
            .then(response => {
                this.rows = response.data.data;
                this.nulo = String(response.data.data[0].Json);
                this.$store.state.planificacionestiba.apiStateForm = ENUM.INIT;
            }).catch( err => {
                this.$store.state.planificacionestiba.messageError = err;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
            
        }).catch( err => {
            this.$store.state.planificacionestiba.messageError = err;
            this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
        });
    }
    //computed
    function weigthText(){
        if(this.unitMeasureId === '' || this.unitMeasureId === null ){
            return 'TON';
        }else{
            let text = '';
            this.dataWeight.map(async (e) => {
                if(this.unitMeasureId === e.UnitMeasureId){
                    text = e.UnitMeasureAbbrev;
                }
            })
            return text;
        }
    }
    function columnDefs(){
        let columnDefs = [
            {
                field: "id",
                headerName: "#",
                filter: 'agNumberColumnFilter',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                lockPosition: true,
                //lockVisible: true,
                suppressMovable: true,
                //pinned: 'left',
                minWidth: 70,
                cellClass: 'gb-cell',
                filter: "agTextColumnFilter",
                //checkboxSelection: true, 
            },
            {
                field: "ArrivalPosition",
                headerName: "ARRIVAL SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 150,
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "DepartPosition",
                headerName: "DEPARTURE SLOT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                filter: "agTextColumnFilter",
                minWidth: 180,
                suppressMovable: true,
                lockPosition: true,
                //pinned: 'left',
            },
            {
                field: "ContainerCode",
                headerName: "CONTAINER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial gb-cell',
                //pinned: 'left',
                suppressMovable: true,
                lockPosition: true,
                resizable: true,
                cellClass: 'gb-cell',
                minWidth: 150,
            },
            {
                field: "TpCargoDetailCode",
                headerName: "SIZE (FT)",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "IsoCode",
                headerName: "ISO CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Seals",
                headerName: "SEALS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "Status",
                headerName: "STATUS",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ShippingLineName",
                headerName: "CARRIER",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "ImdgCode",
                headerName: "IMDG CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                // sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "LoadPort",
                headerName: "LOAD PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
                minWidth: 180,
            },
            {
                field: "DeliveryPort",
                headerName: "DELIVERY PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                //sortable: true,
                lockPosition: true,
                cellClass: 'center-cell-especial',
            },
            {
                field: "TranshipmentPort",
                headerName: "TRANSHIPMENT PORT", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "weigth",
                headerName: "WEIGTH"+' ('+this.weigthText+')',
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && params.value !== 0){
                        variable = parseFloat(params.value).toFixed(2);
                    }
                    return new Intl.NumberFormat("de-DE").format(variable);
                },
                filter: "agNumberColumnFilter",
                minWidth: 150,
            },
            {
                field: "UnNumber",
                headerName: "UN NUMBER", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "restowType",
                headerName: "RESTOW TYPE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "reason",
                headerName: "RESTOW REASON", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 200,
            },
            {
                field: "observation_reason",
                headerName: "OBSERVATION RESTOW", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "YardNamePlanning",
                headerName: "YARD", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "Procedence",
                headerName: "PROCEDENCE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
            {
                field: "FgDirect",
                headerName: "DIRECT DISCHARGE", 
                headerClass: 'center-cell-especial',
                cellClass: 'center-cell-especial',
                filter: "agTextColumnFilter",
                minWidth: 180,
            },
        ]

        return columnDefs;
    }
    function unitMeasureOptions() {
        if (this.dataWeight.length === 0) {
            return [
                {
                    value: null,
                    label: this.$t('label.select'),
                },
            ];
        } else {
            let chart = [];
            this.dataWeight.map(function(e) {
                if (e.FgActUnitMeasure === true) {
                    chart.push({
                        value: e.UnitMeasureId,
                        label: e.UnitMeasureAbbrev,
                    });
                }
            });
            return chart;
        }
    }
    function formatedItems() {
        let computedItems = [];
        if(this.rows.length !== 0 && this.nulo!="null"){
            this.rows.map((item,index)=>{
                computedItems.push({
                    id: index+1,
                    ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                    size: item.Size ? item.Size : '',
                    Status: item.Status ? item.Status : '',
                    DischargePort: item.DischargePort ? item.DischargePort : '',
                    weigth: item.Weigth,
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    UnNumber: item.UnNumber ? item.UnNumber : '',
                    ArrivalPosition: item.ArrivalPosition ? item.ArrivalPosition : '',
                    DepartPosition: item.DepartPosition ? item.DepartPosition : '',
                    restowType: item.TransacStowageName ? item.TransacStowageName : '',
                    reason: item.MovStowageReasonName ? item.MovStowageReasonName : '',
                    observation_reason: item.MovStowageReasonDs ? item.MovStowageReasonDs : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    Seals: item.Seals ? item.Seals : '', 
                    IsoCode: item.IsoCode ? item.IsoCode : '', 
                    TranshipmentPort: item.TranshipmentPort ? item.TranshipmentPort : '',
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '', 
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect? 'YES' : 'NO'
                })
            })
        }
        return computedItems;
    }
    //watch
    function textSearch(newValue) {
        this.gridApi.setQuickFilter(newValue);
    }
    function dropItem(newQuestion,oldQuestion){
        if(newQuestion === 12){
            this.unitMeasureId ='';
            this.SizeChanged = 50;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
            this.getRestowList();
        }else if(oldQuestion === 12){
            this.unitMeasureId ='';
            this.SizeChanged = 50;
            this.rows = [];
            this.gridApi.refreshCells();
            this.textSearch = '';
        }
    }
    function SizeChanged(newQuestion) {
        this.gridApi.paginationSetPageSize(parseInt(newQuestion));
    }
    export default {
        name: "restow-list",
        data,
        beforeMount,
        methods:{
            getRestowList,
            onBtnPdf,
            onBtnExport,
            onGridReady,
            filterUnitMeasureWeight
        },
        mixins: [Reportes, AgGrid],
        computed:{
            weigthText,
            columnDefs,
            formatedItems,
            unitMeasureOptions,
            ...mapState({
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                user: state => state.auth.user,
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            })
        },
        watch:{
            textSearch,
            dropItem,
            SizeChanged,
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    }
</script>

<style lang="scss">
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell-especial{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .gb-cell{
        background-color: rgba(0, 0, 0, 0.07);
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    revo-grid {
        height: 100%;
    }
    .ag-font-style {
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }
    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>